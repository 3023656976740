import {CookieHelper} from "./components/cookieHelper.js";
window.cookieHelper = CookieHelper();
function SliderStepUp(slidername) {
  document.getElementById(slidername).stepUp(1);
}
function SliderStepDown(slidername) {
  document.getElementById(slidername).stepDown(1);
}
window.triggerFileDownload = (fileName, url) => {
  const anchorElement = document.createElement("a");
  anchorElement.href = url;
  anchorElement.download = fileName ?? "";
  anchorElement.click();
  anchorElement.remove();
};
