export const CookieHelper = () => {
  const getCookie = (name) => {
    let matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"));
    return matches ? decodeURIComponent(matches[1]) : null;
  };
  const setCookie = (name, value, days, domain) => {
    let expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1e3);
      expires = "; expires=" + date.toUTCString();
    }
    let domainString = domain ? `; domain=${domain}` : "";
    document.cookie = name + "=" + (value || "") + expires + domainString + "; secure; path=/";
  };
  const removeCookie = (name, domain) => {
    document.cookie = name + "=; Max-Age=-99999999; secure; path=/; domain=" + domain;
  };
  return {
    getCookie,
    setCookie,
    removeCookie
  };
};
